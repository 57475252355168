.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
  }
  
  .login-form {
    margin-bottom: 40px;
    z-index: 1;
  }
  .signup-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    background-color: var(--background-color);
  }
  
  .signup-form {
    margin-bottom: 40px;
    z-index: 1;
  }
  
  .slider-container {
    width: 100%;
    max-width: 800px;
    position: relative;
    /* margin-top: 50px; */
  }
  
  .slider-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .slider-text {
    position: absolute;
    text-align: center;
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  